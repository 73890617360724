import React from "react";
import { StaticQuery, graphql } from "gatsby";

import SEO from "components/seo";
import Layout from "components/layout";

export default () => (
    <StaticQuery
        query={graphql`
            query {
                dataJson(events: {intro: {ne: null}}) {
                    events {
                        intro
                        list {
                            date
                            title
                        }
                    }
                }
            }
        `}
        render={data => (
            <Layout>
                <SEO
                    pageData={{
                        title: `Other events held at the Sydney Football Stadium`,
                        description: `Here are some events that have been held at the Sydney Football Stadium over the years.`
                    }}
                />
                <div className="container-fluid">
                    <h1>Other events held at the SFS</h1>

                    <div className="md:mx-auto mb-12 md:w-3/5" dangerouslySetInnerHTML={{ __html: data.dataJson.events.intro }} />

                    <ul className="md:mx-auto mb-12 md:w-3/5">
                        {data.dataJson.events.list.map((edge,index) => {
                            return (
                                <li className="" key={index}>
                                    <h2 className="" dangerouslySetInnerHTML={{ __html: edge.title }} />
                                    <p dangerouslySetInnerHTML={{ __html: edge.date }} />
                                </li>
                            )
                            })}
                    </ul>
                    
                </div>
            </Layout>
        )}
    />
)
